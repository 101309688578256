.page-status {
	height: 100%;
	padding: 15px 0;
	border-radius: 10px;
	background-color: $c-white;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.status {
		width: 100%;
		max-width: 640px;
		text-align: center;

		&__icon {
			width: 220px;
		}

		&__title {
			margin: 25px 0 0 0;
			color: $c-black;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
			text-align: center;
		}

		&__description {
			margin: 5px 0 25px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
			text-align: center;
		}

		&__text {
			margin: 10px 0;
			text-align: left;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
			flex: 1;
		}

		&__value {
			margin: 10px 0;
			color: $c-black;
			text-align: right;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
			flex: 1;

			&--success {
				color: $c-primary;
			}

			&--failed {
				color: $c-error;
			}
		}

		&__list {
			padding: 25px;
			margin: 0 10px;
			background-color: rgba(211, 224, 232, 0.2);

			@include max-width($scr-sm) {
				padding: 15px;
			}
		}

		&__item {
			list-style-type: none;
			gap: 15px;
			display: flex;
			justify-content: space-between;
		}

		&__button-container {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			@include max-width($scr-sm) {
				flex-direction: column;
			}
		}

		&__button {
			width: 100%;
			max-width: 250px;
			margin: 15px 15px 0;
			padding: 12.5px 20px;
			border-radius: 8px;
			color: $c-white;
			background-color: $c-primary;
			cursor: pointer;
			border: 0;

			&:disabled {
				background-color: $c-light-grey;
			}

			&--text {
				color: $c-primary;
				background-color: transparent;
			}
		}
	}
}
