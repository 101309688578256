* {
	color: $c-black;
	font-weight: normal;
	font-family: $font-family;
	font-size: $text-size-body;
}

#root {
	width: 100%;
	min-height: 100vh;
	display: flex;
}

html,
body {
	margin: 0;
}

body {
	background-color: $c-white;
}
