.app-payment {
	min-height: calc(100vh - 120px);

	.payment {
		min-height: 100%;
		display: flex;
		flex-direction: column;

		&__container {
			gap: 15px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;

			@include max-width($scr-sm) {
				flex-direction: column;
			}
		}

		&__section {
			flex: 2;

			&--total {
				flex: 1;
				border-radius: 10px;
				background-color: $c-white;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			}

			@include max-width($scr-sm) {
				width: 100%;
				flex: 1;
			}
		}

		&__header {
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include max-width($scr-sm) {
				gap: 0;
				flex-direction: column;
				align-items: flex-start;
			}
		}

		&__title {
			margin: 10px 0;
			color: $c-blue;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
			flex: 2;

			@include max-width($scr-sm) {
				margin-bottom: 0;
				flex: 1;
			}
		}

		&__expired {
			margin: 10px 0;
			text-align: right;
			font-size: $text-size-s;
			flex: 1;

			@include max-width($scr-sm) {
				margin-top: 0;
			}

			span {
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}
		}

		&__logo {
			width: 50px;
			height: 17px;
			margin-right: 15px;
		}

		&__card {
			padding: 0;
			margin: 0 0 15px;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

			&--error {
				border: 1px solid $c-error;
			}
		}

		&__card-header {
			width: 100%;
			padding: 15px;
			cursor: pointer;
			border: 0;
			background-color: transparent;
			display: flex;
			align-items: center;

			&--active {
				.payment__chevron {
					transform: rotate(0);
				}
			}

			&:disabled {
				opacity: 0.2;
				cursor: not-allowed;
			}
		}

		&__card-body {
			position: relative;
			padding: 0;
			margin: 0;
			height: 100%;
			max-height: 300px;
			overflow-y: scroll;

			&--credit-card {
				padding: 0 25px 15px;
				height: inherit;
				max-height: inherit;
				overflow-y: inherit;
			}
		}

		&__card-item {
			padding: 0 25px;
			cursor: pointer;
			list-style-type: none;

			&--active {
				background-color: rgba(130, 138, 143, 0.1);
			}
		}

		&__card-wrapper {
			gap: 15px;
			display: flex;
			flex-direction: row;

			.app-input,
			.app-masking-input {
				flex-grow: 1;
			}
		}

		&__card-footer {
			padding: 0 0 15px;
			display: flex;
			align-items: center;
		}

		&__bank-type {
			position: relative;
			padding: 10px 20px;
			margin: 0 0 10px;
			gap: 15px;
			display: flex;
			align-items: center;

			&--active {
				&:after {
					position: absolute;
					bottom: 0;
					left: 20px;
					right: 20px;
					content: "";
					height: 1px;
					margin: 0 auto;
					background-color: $c-border;
				}
			}

			@include max-width($scr-sm) {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		&__radio {
			padding: 0;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;

			@include max-width($scr-sm) {
				margin: 0 0 10px;
			}

			&--active {
				.payment__dot {
					&:before {
						content: "";
						width: 10px;
						height: 10px;
						border-radius: 100px;
						background-color: $c-primary;
						display: block;
					}
				}
			}
		}

		&__dot {
			position: relative;
			width: 20px;
			height: 20px;
			margin: 0 15px 0 0;
			border-radius: 100px;
			border: 1px solid $c-border;
			background-color: $c-white;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}

		&__bank {
			width: 100%;
			padding: 10px 0;
			background-color: transparent;
			cursor: pointer;
			border: 0;
			display: flex;
			align-items: center;

			&:disabled {
				opacity: 0.5;
				cursor: no-drop;
			}
		}

		&__text {
			margin: 0;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__bank-name {
			margin: 0;
			color: #000000;
			font-size: $text-size-s;
		}

		&__note {
			margin: 0 5px;
			text-align: center;
			color: $c-light-grey;
			font-size: $text-size-xs;
		}

		&__checked {
			margin-left: auto;
		}

		&__cc {
			margin-left: auto;
			pointer-events: none;
		}

		&__chevron {
			margin-left: auto;
			transform: rotate(180deg);
		}

		&__error {
			margin: 0 0 15px;
			color: $c-error;
			font-size: $text-size-xs;
		}

		&__list {
			margin: 0;
			padding: 0;
		}

		&__item {
			padding: 15px 20px;
			list-style-type: none;
			display: flex;
			justify-content: space-between;

			&:first-child {
				border-bottom: 1px solid $c-border;
			}
		}

		&__total-payment {
		}

		&__tnc {
			position: relative;
			margin: 10px 0 0;
			padding: 15px 25px;

			&:before {
				position: absolute;
				top: 0;
				left: 20px;
				right: 20px;
				content: "";
				height: 1px;
				margin: 0 auto;
				background-color: $c-border;
			}

			a {
				color: #13a7f4;
				text-decoration: none;
				font-size: $text-size-xs;
				cursor: pointer;

				&:after {
					content: "*";
					color: $c-error;
					font-size: $text-size-xs;
					cursor: pointer;
				}
			}
		}

		&__footer {
			margin: 25px 0 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			@include max-width($scr-sm) {
				justify-content: center;
			}
		}

		&__button {
			width: 100%;
			max-width: 220px;
			margin: 0;
			padding: 15px;
			color: $c-white;
			border-radius: 8px;
			font-size: $text-size-s;
			background-color: $c-primary;
			border: 0;
			cursor: pointer;

			&--outline {
				margin: 0 15px;
				color: $c-primary;
				background-color: $c-white;
				border: 1px solid $c-primary;
			}

			&:disabled {
				opacity: 0.5;
			}
		}
	}
}
