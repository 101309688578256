$font-family: Poppins, Roboto, sans-serif;
$transition: 150ms cubic-bezier(0.1, 0.7, 0.6, 0.9);

/* -- Color -- */
$c-primary: #0073f9;
$c-blue: #0245a9;
$c-white: #ffffff;
$c-black: #333333;
$c-dark-grey: #666666;
$c-light-grey: #c0c0c0;
$c-green: #00cf9c;
$c-border: #d9d9d9;
$c-error: #f64444;
$c-background: #f6fbff;

/* -- Font size -- */
$text-size-xxxl: 48px;
$text-size-xxl: 32px;
$text-size-xl: 28px;
$text-size-l: 22px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-exbold: 800;
$text-weight-bold: 700;
$text-weight-semibold: 600;
$text-weight-normal: 500;
$text-weight-regular: 400;
$text-weight-thin: 300;
$text-weight-light: 200;
$text-weight-exlight: 100;

/* -- Media query -- */
$scr-xlg: "1440px";
$scr-lg: "1199px";
$scr-md: "1024px";
$scr-sm: "768px";
$scr-xs: "679px";
