.app-layout {
	width: 100%;

	.layout {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		&__header {
			width: 100%;
			height: 60px;
			background-color: rgb(3, 149, 150);
			background: linear-gradient(157deg, rgba(3, 149, 150, 1) 0%, rgba(9, 166, 148, 1) 40%, rgba(22, 202, 144, 1) 100%);
		}

		&__body {
			width: 100%;
			height: 100%;
			max-width: 1024px;
			padding: 20px;
			margin: 0 auto;
		}

		&__footer {
			padding: 10px;
			display: flex;
			justify-content: center;
		}

		&__powered-by {
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__logo {
			margin: 0 10px;
			width: 100px;
		}
	}
}
