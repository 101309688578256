.page-connection-failed {
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.connection-failed {
		width: 100%;
		max-width: 640px;
		text-align: center;

		&__icon {
			width: 125px;
		}

		&__title {
			margin: 25px 0 0 0;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
			text-align: center;
		}

		&__description {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
			text-align: center;
		}

		&__button {
			width: 100%;
			max-width: 250px;
			margin: 15px 0 0;
			padding: 12.5px 20px;
			border-radius: 8px;
			color: $c-white;
			background-color: $c-primary;
			cursor: pointer;
			border: 0;
		}
	}
}
