.app-input {
	margin: 0 0 15px;

	.input {
		display: flex;
		flex-direction: column;

		&__label {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
		}

		&__input {
			width: 100%;
			border: 0;
			outline: none;
			margin: 0 0 5px;
			padding: 10px 0;
			border-bottom: 1px solid #d3e0e8;

			&::placeholder {
				color: #c0c0c0;
			}
		}

		&__error {
			margin: 0;
			color: $c-error;
			font-size: $text-size-xs;
		}
	}
}
