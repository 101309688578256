.app-checkbox {
	display: flex;
	align-items: center;

	&__button {
		border: 0;
		padding: 0;
		background-color: transparent;
		cursor: pointer;
		display: flex;
		justify-content: center;

		&--active {
			//
			.app-checkbox {
				//
				&__box {
					border: 1px solid $c-primary;
				}

				&__icon {
					//
					path {
						stroke: $c-primary;
					}
				}
			}
		}
	}

	&__box {
		width: 20px;
		height: 20px;
		padding: 3px;
		border-radius: 4px;
		border: 1px solid $c-border;
		pointer-events: none;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__icon {
		//
		path {
			stroke: $c-white;
		}
	}

	&__label {
		margin: 0 0 0 15px;
		text-align: left;
		font-size: $text-size-xs;
	}
}
